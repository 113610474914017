





































import { Component, Vue } from 'vue-property-decorator';
import { readAdminDocumentoCategorias } from '@/store/admin/getters';
import {
  dispatchGetDocumentosCategorias,
  dispatchDeleteDocumento,
} from '@/store/admin/actions';
import { apiUploadFolder } from '@/env';

@Component
export default class AdminDocumentos extends Vue {
  public alertMsg = false;

  public headers = [
    {
      text: 'Nombre',
      sortable: true,
      value: 'categoria',
      align: 'left',
    },
    {
      text: 'Alias',
      sortable: true,
      value: 'alias',
      align: 'left',
    },

    {
      text: 'Acciones',
      align: 'center',
      value: 'id',
    },
  ];

  public url(u) {
    return apiUploadFolder + u;
  }
  get categorias() {
    return readAdminDocumentoCategorias(this.$store);
  }

  public async mounted() {
    await dispatchGetDocumentosCategorias(this.$store);
  }
  public async borrarDocumento(id) {
    await dispatchDeleteDocumento(this.$store, id);
    this.alertMsg = false;
    // await dispatchGetDocumentos(this.$store);
  }
}
